import Swiper, { Navigation } from 'swiper';

let newsTagsSwiperWr = document.querySelectorAll('.news-tags-swiper-wr');
newsTagsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentNode;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation],
      observer: true,
      observeParents: true,
      // autoHeight: true,
      freeMode: true,
      spaceBetween: 14,
      slidesPerView: 'auto',
      threshold: 10,
      breakpoints: {
        640: {
          spaceBetween: 17,
        },
      },
    });

  }
});
