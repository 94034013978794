import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select.component.js';
import './components/range-slider.component.js';

import './libs/countUp.lib.js';

import './components/swiper-markets.component.js';
import './components/swiper-partner.component.js';
import './components/swiper-values.component.js';
import './components/swiper-recent-jobs.component.js';
import './components/swiper-reviews.component.js';
import './components/swiper-news.component.js';
import './components/swiper-footer.component.js';
import './components/swiper-upload-cv.component.js';
import './components/swiper-why.component.js';
import './components/swiper-our-team.component.js';
import './components/swiper-news-tags.component.js';
import './components/swiper-solutions.component.js';
import './components/swiper-milestones.component.js';
import './components/swiper-team.component.js';
import './components/swiper-countries.component.js';
import './components/swiper-benefits.component.js';
import './components/swiper-impact.component.js';
import './components/swiper-clients.component.js';

import './components/help.component.js';
import './components/animations.component.js';
import './components/news-tags.component.js';
import './components/jobs-search.component.js';
import './components/contact.component.js';

// FILE UPLOAD NAME
document.querySelectorAll('.file-input').forEach(function (input) {
  input.addEventListener('change', function () {
    const fileNameElement = this.nextElementSibling.querySelector('.file-name');
    if (this.files.length > 0) {
      fileNameElement.textContent = this.files[0].name;
      fileNameElement.style.display = 'block';
    } else {
      fileNameElement.textContent = '';
      fileNameElement.style.display = 'none';
    }
  });
});



document.addEventListener('DOMContentLoaded', () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari) {
    document.documentElement.classList.add('safari-browser');
  }
});

if(document.querySelector('section.job-descr')) {
  document.querySelector('footer').classList.add('job-descr-footer');
}

if(document.querySelector('.contact-forms')) {
  document.querySelector('footer').classList.add('job-descr-footer');
}

if(document.querySelector('.page-join-wrapper')) {
  document.querySelector('footer').classList.add('join-page');
}
