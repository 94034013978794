import Swiper from 'swiper';
import { swiperMarquee } from '../utils/swiperMarquee.utils.js';

let countriesSwiperWr = document.querySelectorAll('.countries-swiper-wr');
countriesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let swiper = new Swiper(swiperEl, {
      spaceBetween: 40,
      slidesPerView: 'auto',
      threshold: 10,
      loop: true,
      loopedSlides: 3,
      breakpoints: {
        1200: {
          spaceBetween: 90,
        },
      },
    });

    swiperMarquee(swiper, {
      speed: 1
    })
  }
});
