import Swiper, { EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let solutionsSwiperWr = document.querySelectorAll('.solutions-swiper-wr');
solutionsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');

    let swiper = new Swiper(swiperEl, {
      modules: [EffectFade],
      slidesPerView: 1,
      autoHeight: true,
      spaceBetween: 0,
      allowTouchMove: false, 
      effect: 'fade',
      fadeEffect: {
        crossFade: true 
      },
      breakpoints: {
        '768': {
          autoHeight: false
        }
      },
      speed: 300 
    });

    const tabs = document.querySelectorAll('.section-solutions-tab');

    tabs.forEach((tab, i) => {
      tab.addEventListener('click', function() {
        tabs.forEach(t => t.classList.remove('active'));
        this.classList.add('active');
        swiper.slideTo(i);
      })
    })

    swiper.on('slideChange', function () {
      tabs.forEach((tab, i) => {
        if (i === swiper.activeIndex) {
          tab.classList.add('active');
        } else {
          tab.classList.remove('active');
        }
      });
    });
  }
});