import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let impactSwiperWr = document.querySelectorAll('.impact-swiper-wr');
impactSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Autoplay],
      observer: true,
      observeParents: true,
      spaceBetween: 0,
      slidesPerView: 1,
      threshold: 10,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });

    swiperObserver(swiper);
  }
});
