import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let newsSwiperWr = document.querySelectorAll('.news-swiper-wr');
newsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentNode;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 1260px)', swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 24,
      slidesPerView: 1,
      threshold: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        }
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });

    // let swiper = new Swiper(swiperEl, {
    //   modules: [Autoplay, Navigation],
    //   observer: true,
    //   observeParents: true,
    //   spaceBetween: 24,
    //   slidesPerView: 1,
    //   threshold: 10,
    //   breakpoints: {
    //     1200: {
    //       spaceBetween: 30,
    //     }
    //   },
    //   navigation: {
    //     prevEl: prevEl,
    //     nextEl: nextEl,
    //   },
    // });

  }
});
