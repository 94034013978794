window.choicesArray = [];

window.initChoices = function initChoices(elementsArr) {
  const choicesDOM = document.querySelector(elementsArr);
  if (choicesDOM) {
    const choicesArr = document.querySelectorAll(`${elementsArr}`);
    for (let i = 0; i < choicesArr.length; i++) {
/*
      console.log('init');
*/
      const parentContainer = choicesArr[i].parentNode;
      /*const list = parentContainer.querySelector(".default-select__list");*/

      const choices = new Choices(choicesArr[i], {
        searchEnabled: false,
        itemSelectText: '',
        placeholder: true,
        searchPlaceholderValue: 'Search',

        removeItems: false,
        removeItemButton: false,
      });
      choicesArray.push(choices);

      const choicesMultipleElement = parentContainer.querySelector(".choices[data-type='select-multiple']");

      if (choicesMultipleElement) {
        choicesMultipleElement.addEventListener('click', () => {
          if (parentContainer.querySelector('.is-open')) {
            choices.hideDropdown();
          }
        });
      }
    }
  }
};

window.initChoices('[data-choices]');

window.getChoice = function getChoice(select) {
  let selectEl = typeof select === 'object' ? select : document.querySelector(select);
  let choicesArr = window.choicesArray;
  let targetChoice;
  if (choicesArr) {
    window.choicesArray.forEach((choices) => {
      let choicesSelect = choices.passedElement.element;
      if (choicesSelect == selectEl) {
        targetChoice = choices;
      }
    });
  }
  return targetChoice;
};

window.resetChoice = function resetChoice(select) {
  let choice = getChoice(select);
  if (choice) {
    let startValue = choice.config.choices[0].value;
    choice.setChoiceByValue(startValue);
  }
};
