document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('[data-scroll-section]');
  const body = document.querySelector('body')
  let lastScrollY = window.scrollY;

  const handleScroll = () => {

    const windowHeight = window.innerHeight;
    const currentScrollY = window.scrollY;
    const isScrollingUp = currentScrollY < lastScrollY;

    sections.forEach((section) => {

      const prevSection = section.previousElementSibling;
      const nextSection = section.nextElementSibling;

      const rect = section.getBoundingClientRect();
      const sectionTop = rect.top;

      const visiblePercentage = (sectionTop / windowHeight) * 100;


      if (visiblePercentage <= 40 && visiblePercentage >= 0) {
        section.classList.add('active')
        section.classList.remove('unactive-section');
        body.classList = ''
/*
        console.log(section.getAttribute('data-bg-color'));
*/
        body.classList.add(`${section.getAttribute('data-bg-color')}`)

        // Робимо інші секції неактивними
        sections.forEach((otherSection) => {
          if (otherSection !== section) {
            // body.classList.remove(`${otherSection.getAttribute('data-bg-color')}`)
            // body.classList = ''

            // prevSection.classList.remove(`${otherSection.getAttribute('data-bg-color')}`)
            // nextSection.classList.remove(`${otherSection.getAttribute('data-bg-color')}`)

            otherSection.classList.add('unactive-section');
          }
        });
      }

      if (visiblePercentage >= 60 && !section.classList.contains('unactive-section') && isScrollingUp) {
        section.classList.add('unactive-section');
        prevSection.classList.remove('unactive-section');
        body.classList = ''
        body.classList.add(`${prevSection.getAttribute('data-bg-color') ? prevSection.getAttribute('data-bg-color') : "base"}`)
      }
    });

    lastScrollY = currentScrollY;
  };

  window.addEventListener('scroll', handleScroll);

  handleScroll();
});




// first gradient

const gradientElements = document.querySelectorAll('.gradient-animation');
let isAnimationEnabled = window.innerWidth > 992;
let ticking = false;

// Функция обработки прокрутки с использованием requestAnimationFrame
function handleScroll() {
  if (!isAnimationEnabled || ticking) return;

  ticking = true;
  requestAnimationFrame(() => {
    gradientElements.forEach((element) => {
      const rect = element.getBoundingClientRect();

      if (rect.top < window.innerHeight && rect.bottom > 0) {
        const visiblePercentage = Math.min(
          (window.innerHeight - rect.top) / (window.innerHeight + rect.height),
          1
        );

        const translateX = -600 + visiblePercentage * 650; // 650 = 50 - (-600)
        const currentTransform = element.style.transform;
        const newTransform = `translateX(${translateX}px)`;

        if (currentTransform !== newTransform) {
          element.style.transform = newTransform;
        }
      }
    });

    ticking = false;
  });
}

// Функция для управления видимостью элементов
function observeElements() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        entry.target.style.transform = 'translateX(0px)';
      }
    });
  });

  gradientElements.forEach((element) => observer.observe(element));
}

// Функция для обновления статуса анимации
function updateAnimationStatus() {
  isAnimationEnabled = window.innerWidth > 992;

  if (!isAnimationEnabled) {
    gradientElements.forEach((element) => {
      element.style.transform = 'translateX(0)';
    });
  }
}

// Функция для плавного отображения элементов
function showElementsWithDelay() {
  setTimeout(() => {
    gradientElements.forEach((element) => {
      element.style.opacity = '1';
    });
  }, 100);
}

// Слушатели событий
window.addEventListener('scroll', handleScroll);
window.addEventListener('resize', () => {
  updateAnimationStatus();
  observeElements();
});

// Инициализация
updateAnimationStatus();
observeElements();
handleScroll();
showElementsWithDelay();
