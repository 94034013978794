import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let milestonesSwiperWr = document.querySelectorAll('.milestones-swiper-wr');
milestonesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentNode;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let sliders = el.querySelectorAll('.swiper-slide')
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 14,
      slidesPerView: 1,
      threshold: 10,
      slideToClickedSlide: true,
      speed: 800,
      breakpoints: {

      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
    swiper.on('touchEnd', () => {
      if (swiper.swipeDirection === 'next') {
        swiper.slideTo(swiper.activeIndex + 1);
      } else if (swiper.swipeDirection === 'prev') {
        swiper.slideTo(swiper.activeIndex - 1); 
      }
    });
    swiper.on('slideNextTransitionStart', () => {
      sliders[swiper.activeIndex].style.marginLeft = `${216 + swiper.activeIndex * 5}px`;
    });

    swiper.on('slidePrevTransitionStart', () => {
      sliders[swiper.activeIndex + 1].style.marginLeft = `${0}px`;
    });
  }
});
