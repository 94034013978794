import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let teamSwiperWr = document.querySelectorAll('.team-swiper-wr');
teamSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Autoplay],
      observer: true,
      observeParents: true,
      spaceBetween: 14,
      slidesPerView: 1,
      initialSlide: 1,
      loop: true,
      threshold: 10,
      breakpoints: {
        768: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 24,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 3,
        }
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });

    swiperObserver(swiper);
  }
});
