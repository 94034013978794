document.addEventListener('DOMContentLoaded', () => {
  const rangeContainer = document.querySelector('.double-range-container');
  const minInput = document.getElementById('double-range-value-min');
  const maxInput = document.getElementById('double-range-value-max');
  const unitElement = document.querySelectorAll('.unit');
  const unitSymbol = rangeContainer?.dataset.unit || '';

  unitElement?.forEach((item) => (item.innerHTML = unitSymbol));

  // Перевірка, щоб мінімальне значення не було більше за максимальне
  const validateRange = () => {
    const minValue = parseInt(minInput.value.replace(/\D/g, '')) || 1;
    const maxValue = parseInt(maxInput.value.replace(/\D/g, '')) || 0;

    if (minValue > maxValue) {
      minInput.value = `${rangeContainer.dataset.min}`;
      maxInput.value = `${rangeContainer.dataset.max}`;
    }
  };

  // Заборона від'ємних значень
  const preventNegativeInput = (e) => {
    e.target.value = e.target.value.replace(/-/g, '');
  };

  // Додаємо обробку для запобігання від'ємним значенням та перевірку значення
  minInput?.addEventListener('input', (e) => {
    preventNegativeInput(e);
    validateRange();
  });

  maxInput?.addEventListener('input', (e) => {
    preventNegativeInput(e);
    validateRange();
  });
});
