import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperMarquee } from '../utils/swiperMarquee.utils.js';

let reviewsSwiperWr = document.querySelectorAll('.reviews-swiper-wr');
reviewsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentNode;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      // autoHeight: true,
      spaceBetween: 16,
      slidesPerView: 1,
      threshold: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        769: {
          spaceBetween: 24,
          autoHeight: false,
          slidesPerView: 2,
        },
        992: {
          spaceBetween: 24,
          slidesPerView: 3,
          autoHeight: false,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
          autoHeight: false,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });

  }
});
