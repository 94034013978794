const toggles = document.querySelectorAll('.contact-toggle'),
      forms = document.querySelectorAll('.contact-form');

if(toggles.length && forms.length) {
  toggles[0].classList.add('active')
  forms[0].classList.add('active')
  toggles.forEach((toggle, i) => {
    toggle.addEventListener('click', () => {
      if(toggle.classList.contains('active')) return
  
      toggles.forEach(t => t.classList.remove('active'));
      forms.forEach(f => f.classList.remove('active'));
  
      toggles[i].classList.add('active');
      forms[i].classList.add('active');
    })
  })
}
