const headerComponent = document.getElementsByClassName('header')[0];

// Header scroll
const scrollContainer = () => document.documentElement || document.body;

/*let ticking = false;

document.addEventListener('scroll', () => {
  if (!ticking) {
    requestAnimationFrame(() => {
      const scrollTop = scrollContainer().scrollTop;

      if (scrollTop > 200) {
        headerComponent.classList.add('scrolled');
      } else if (scrollTop === 0) {
        headerComponent.classList.remove('scrolled');
      }

      ticking = false;
    });

    ticking = true;
  }
});*/

// menu handlers

// check mobile menu show/hide condition
const mobileMenuStartPoint = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue(
    '--mobile-menu-start-point'
  )
);
let isMobileMenuEnable =
  window.outerWidth <= mobileMenuStartPoint ||
  document.querySelector('.header-mobile');

const dropdownToggle = document.querySelectorAll('.dropdown-toggle');

dropdownToggle.forEach((toggle) => {
  toggle.addEventListener('click', function (e) {
    if (isMobileMenuEnable) {
      const menuItem = this.closest('.menu-item.dropdown');
      const dropdownMenu = this.nextElementSibling;

      // close all opened sub menu
      const activeDropdowns = document.querySelectorAll(
        '.menu-item.dropdown.active .dropdown-menu'
      );
      activeDropdowns.forEach((menu) => {
        menu.style.display = 'none';
        menu.closest('.dropdown').classList.remove('active');
      });

      // open current submenu
      menuItem.classList.toggle('active');
      if (menuItem.classList.contains('active')) {
        e.preventDefault();
        dropdownMenu.style.display = 'block';
      }
    }
  });
});

// toggle menu handler
function menuToggleFunc() {
  const menuToggle = document.querySelector('.menu-toggle');
  const navbarNav = document.querySelector('.navbar-nav');
  const headerCloseWrapper = document.querySelector('.header-close-wrapper');
  const headerEl = document.querySelector('.header')
  
/*
  console.log(headerEl);
*/

  menuToggle.classList.toggle('active');
  navbarNav.classList.toggle('active');
  headerCloseWrapper.classList.toggle('active');
  headerEl.classList.toggle('opened');

  // LockScroll when burger open and enable when closed and enable scroll on menu
  if (scrollLock.getScrollState()) {
    scrollLock.disablePageScroll(document.querySelector('.simplebar-content-wrapper'));
    // scrollLock.addScrollableSelector('.simplebar-content-wrapper');
  } else {
    scrollLock.enablePageScroll();
  }
}

// menu update function
function updateMenu() {
  isMobileMenuEnable =
    window.outerWidth <= mobileMenuStartPoint ||
    document.querySelector('.mobile-header');

  if (!isMobileMenuEnable) {
    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
    dropdownMenus.forEach((menu) => {
      menu.style.display = '';
    });

    const headerCloseWrapper = document.querySelector('.header-close-wrapper');
    headerCloseWrapper.classList.remove('active');

    const activeMenuItems = document.querySelectorAll('.menu-item.active');
    activeMenuItems.forEach((item) => {
      item.classList.remove('active');
    });

    const navbarNav = document.querySelector('.navbar-nav');
    navbarNav.classList.remove('active');

    const menuToggle = document.querySelector('.menu-toggle');
    menuToggle.classList.remove('active');

    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}

window.addEventListener('resize', updateMenu);
window.addEventListener('orientationchange', updateMenu);

// end of toggle menu handler

const menuToggle = document.querySelector('.menu-toggle');
const headerCloseWrapper = document.querySelector('.header-close-wrapper');
// const headerBlur = document.querySelector('.header-blur')

menuToggle.addEventListener('click', menuToggleFunc);
headerCloseWrapper.addEventListener('click', menuToggleFunc);

// Hide and show Header when scroll up
function headerSticky() {
  let prevScrollPos = window.pageYOffset;
  const headerHeight = headerComponent.scrollHeight;
  let ticking = false;

  function updateHeader() {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos > 0) {
      if (prevScrollPos > currentScrollPos) {
        headerComponent.style.top = 'var(--header-top, 25px)';
        headerComponent.classList.add('scrolled');
        // headerBlur.classList.remove('active');
      } else {
        headerComponent.style.top = `calc(-${headerHeight}px - var(--header-top, 25px))`;
        headerComponent.classList.remove('scrolled');
        // headerBlur.classList.add('active');
      }
    } else {
      headerComponent.classList.remove('scrolled');
      // headerBlur.classList.add('active');
    }

    prevScrollPos = currentScrollPos;
    ticking = false;
  }

  function onScroll() {
    if (!ticking) {
      requestAnimationFrame(updateHeader);
      ticking = true;
    }
  }

  if (window.scrollY !== 0) {
    headerComponent.classList.add('scrolled');
  }

  window.addEventListener('scroll', onScroll);
}

// headerSticky();

