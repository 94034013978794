import Swiper, { Autoplay, Navigation, Grid } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let clientsSwiperWr = document.querySelectorAll('.clients-swiper-wr');
clientsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    
    swiperOnResize('(max-width: 768px)', swiperEl, {
      modules: [Autoplay, Navigation, Grid], 
      slidesPerView: 2, 
      grid: {
        rows: 2, 
        fill: 'row', 
      },
      spaceBetween: 30,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          grid: {
            rows: 2, 
          },
        },
      },
    });
  }
});
