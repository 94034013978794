import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let ourTeamCvSwiperWr = document.querySelectorAll('.our-team-swiper-wr');
ourTeamCvSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentNode;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 14,
      slidesPerView: 1,
      loop: true,
      loopedSlides: slidesCount * 3,
      threshold: 10,
      breakpoints: {
        552: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1024: {
          spaceBetween: 24,
          slidesPerView: 4,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 6,
        }
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });

  }
});
