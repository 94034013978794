import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperMarquee } from '../utils/swiperMarquee.utils.js';

let uploadCvSwiperWr = document.querySelectorAll('.upload-cv-swiper-wr');
uploadCvSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentNode;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slides = el.querySelectorAll('.swiper-slide');
    let slidesCount = slides.length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    if (slidesCount > 2) {
      let clonedSlides = [];

      for (let i = 0; i < 2; i++) {
        slides.forEach((slide) => {
          const clonedSlide = slide.cloneNode(true);
          clonedSlides.push(clonedSlide);
        });
      }

      clonedSlides.forEach((clone) => {
        wrapper.appendChild(clone);
      });
    }

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 8,
      slidesPerView: 1,
      loop: true,
      loopedSlides: slidesCount * 3,
      threshold: 10,
      breakpoints: {
        420: {
          spaceBetween: 12,
        },
        768: {
          spaceBetween: 16,
        },
        1024: {
          spaceBetween: 24,
        },
        1200: {
          spaceBetween: 30,
        },
        1279: {
          spaceBetween: 30,
          slidesPerView: 2,
        }
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });

  }
});
