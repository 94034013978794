const helpCards = document.querySelectorAll('.help-card');

const setEqualHeights = () => {
  let maxHeight = 0;

  // Знаходимо максимальну висоту і одночасно скидаємо попередню мінімальну висоту
  helpCards.forEach((card) => {
    const cardTitle = card.querySelector('.help-card__title');
    cardTitle.style.minHeight = ''; // Скидаємо попередню висоту

    const height = cardTitle.offsetHeight;
    if (height > maxHeight) {
      maxHeight = height;
    }
  });

  // Встановлюємо мінімальну висоту всім тайтлам
  helpCards.forEach((card) => {
    const cardTitle = card.querySelector('.help-card__title');
    cardTitle.style.minHeight = `${maxHeight}px`;
  });
};

// Викликаємо функцію при завантаженні сторінки та при зміні розміру
window.addEventListener('resize', setEqualHeights);
window.addEventListener('load', setEqualHeights);
