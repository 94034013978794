import Swiper, { Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperMarquee } from '../utils/swiperMarquee.utils.js';

let partnersSwiperWr = document.querySelectorAll('.partners-swiper-wr');
partnersSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      // observer: true,
      // observeParents: true,
      // spaceBetween: 56,
      slidesPerView: 'auto',
      threshold: 10,
      loop: slidesCount > 5,
      loopedSlides: slidesCount > 5 ? slidesCount * 2 : false,
      breakpoints: {
        // 768: {
        //   spaceBetween: 82,
        // },
        // 1200: {
        //   spaceBetween: 168,
        // },
      },
    });

    swiperMarquee(swiper, {
      speed: 0.5
    })
  }
});
